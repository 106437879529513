import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useToaster } from "hooks/useToaster";
import { LoadingOverlay } from "components/sredx-ui/LoadingOverlay/LoadingOverlay";
import { Button } from "components/sredx-ui/Button";
import { Card, CardBody } from "components/sredx-ui/Card";
import { useGetGitopsAccounts } from "services/gitops/useGetGitopsAccounts";
import { useGetCspAccounts } from "services/csp/useGetCspAccounts";
import { ControlplaneCreationDTO, controlplanesCreationDSchema } from "types/controlplanes/controlplanes";
import { ControlplaneCreateForm } from "components/controlplanes/ClusterCreateForm/ClusterCreateForm";
import { useCreateControlplane } from "services/controlplanes/useCreateControlplane";


interface ControlplaneCreateContainerProps {
  onCancel: () => void;
}

export const ControlplaneCreateContainer = ({
  onCancel,
}: ControlplaneCreateContainerProps) => {
  const { mutate, isLoading } = useCreateControlplane();
  const { addToast } = useToaster();
  const { data: cspAccounts = [] } = useGetCspAccounts();
  const { data: gitopsAccounts = [] } = useGetGitopsAccounts();


  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(controlplanesCreationDSchema),
  });

  const handleClickCreate = () => {
    const clusterCreate = (data: ControlplaneCreationDTO) => {
      mutate(data, {
        onSuccess: () => {
          addToast({
            message: `Controlplane ${data.name} created successfully.`,
            type: "success",
          });
          onCancel();
        },
        onError: (error: any) => {
          addToast({
            message: `Error occured while creating cluster ${data.name}. ${error.message}`,
            type: "error",
          });
        },
      });
    };
    methods.handleSubmit((data) => {
      clusterCreate(data)
    })();
  };

  return (
    <Card>
      <CardBody>
        <div
          style={{
            minWidth: "800px",
            padding: "24px 8px",
          }}
        >
          <div className="relative">
            {isLoading && <LoadingOverlay message="Creating Cluster" />}
            <h1>Create new cluster</h1>
            <FormProvider {...methods}>
              <ControlplaneCreateForm
                errors={methods.formState.errors}
                cloudAccounts={cspAccounts}
                gitopsAccounts={gitopsAccounts}
              />
            </FormProvider>
            <div className="flex justify-end gap-4 mt-6">
              <button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </button>
              <Button  onClick={handleClickCreate}>
                Confirm
              </Button>
            </div>
            </div>
        </div>
      </CardBody>
    </Card>
  );
};
