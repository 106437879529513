export const QUERY_KEYS = {
  clusters: "CLUSTERS",
  clusterDetails: "CLUSTER_DETAILS",
  clusterManagedResources: "CLUSTER_MANAGED_RESSOURCES",
  clusterFoundations: "CLUSTER_DETAIL_FOUNDATIONS",

  controlplanes : "CONTROLPLANES",
  ControlplaneDetails: "CONTROLPLANE_DETAILS",

  ControlplaneManagedResources: "CONTROLPLANE_MANAGED_RESSOURCES",
  ControlplaneFoundations: "CONTROLPLANE_DETAIL_FOUNDATIONS",

  // kube config
  kubeConfig: "KUBE_CONFIG",

  cspAccounts: "CSP_ACCOUNTS",

  foundationTemplates: "FOUNDATION_TEMPLATES",

  gitopsAccounts: "GITOPS_ACCOUNTS",
};
export const MUTATION_KEYS = {
  createCluster: "CREATE_CLUSTER",
  deleteCluster: "DELETE_CLUSTER",

  createCspAccount: "CREATE_CSP_ACCOUNT",
  deleteCspAccount: "DELETE_CSP_ACCOUNT",

  createFoundationTemplate: "CREATE_FOUNDATION_TEMPLATE",
  deleteFoundationTemplate: "DELETE_FOUNDATION_TEMPLATE",

  createGitopsAccount: "CREATE_GITOPS_ACCOUNT",
  deleteGitopsAccount: "DELETE_GITOPS_ACCOUNT",

  createControlplane: "CREATE_CONTROLPLANE",
  deleteControlplane: "DELETE_CONTROLPLANE"

}