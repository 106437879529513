import { TrashIcon } from "@heroicons/react/outline";
import styles from "./styles.module.css";
import { GitopsAccountsDto } from "types/gitops/gitops";
import { ListingCard } from "components/sredx-ui/ListingCard";
import { StatusTag } from "components/shared/StatusTag/StatusTag";

interface GitopsAccountCardProps {
  gitopsAccount: GitopsAccountsDto;
  onDelete?: (gitopsAccount: GitopsAccountsDto) => void;
}

export const GitopsAccountCard = ({
  gitopsAccount,
  onDelete,
}: GitopsAccountCardProps) => {
  return (
    <ListingCard >
      <div>
        <div className={styles.head}>
          <h2 className={styles.title}>{gitopsAccount.name}</h2>
          <div className="flex gap-2 flex-shrink-0">
            {onDelete && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(gitopsAccount);
                }}
              >
                <TrashIcon className="h-4 text-slate-500" />
              </button>
            )}
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          <StatusTag status={gitopsAccount.phase} />
        </div>
      </div>
      {/* Body Section */}
      <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Name</h3>
          <div className="flex gap-2" data-testid="tools">
            {/* annotations */}
            <div className={"flex gap-2"}>

              <span className="text-slate-500 text-xs">{gitopsAccount.name}</span>

            </div>
          </div>
        </div>
      <div className="flex flex-col gap-2">
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Owner</h3>
          <div className="flex items-center gap-2">
            <span className="text-slate-500 text-xs">{gitopsAccount.owner}</span>
          </div>
        </div>
      </div>
      {
        gitopsAccount.phase != "RUNNING" &&
        <div className="flex flex-col gap-2">
          <div className={styles.keyValue}>
            <h3 className={styles.subtitle}>Error Message</h3>
            <div className="flex items-center gap-2">
              <span className="text-red-500 text-xs">{gitopsAccount.message}</span>
            </div>
          </div>
        </div>
      }
    </ListingCard>
  );
};