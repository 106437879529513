import { API_ENDPOINTS } from "config/apiPaths";
import { QUERY_KEYS } from "config/queryKeys";
import { useQuery } from "react-query";
import { ClientApi } from "services/clientApi";
import { Controlplane } from "types/controlplanes/controlplanes";

export const useGetControlplanes = () => {
  return useQuery<Controlplane[]>({
    queryKey: [QUERY_KEYS.controlplanes],
    queryFn: async () => await ClientApi.get(API_ENDPOINTS.getControlplanes()),
    retry: 5,
    retryDelay: 1000,
  });
};
