import { CubeTransparentIcon, PuzzleIcon } from "@heroicons/react/outline";
import { CloudIcon, CodeIcon, CogIcon, EdgeClusterIcon, HomeIcon } from "../Icon";
import { CLUSTERS_PAGE, CONTROLPLANS_PAGES, CSP_ACCOUNTS_PAGE, FOUNDATION_TEMPLATES_PAGE, GITOPS_ACCOUNTS_PAGE, HOME_PAGE, SETTINGS_PAGE } from "routes/paths";

export const MENU = [
  {
    items: [
      {
        title: "Home",
        icon: HomeIcon,
        path: HOME_PAGE,
      },
    ],
  },
  {
    title: "Self Service",
    items: [
      {
        title: "Clusters",
        icon: EdgeClusterIcon,
        path: CLUSTERS_PAGE,
      },
      // {
      //   title: "Databases",
      //   icon: DatabaseIcon,
      //   path: DATABASES_PAGE,
      // }
    ],
  },
  {
    title: "Presets",
    items: [
      // {
      //   title: "Stacks",
      //   icon: BoxIcon,
      //   path: STACKS_PAGE,
      // },
      {
        title: "Foundations",
        icon: PuzzleIcon,
        path:FOUNDATION_TEMPLATES_PAGE,
      }
    ],
  },
  {
    title: "Accounts",
    items: [
      {
        title: "CSP Accounts",
        icon: CloudIcon,
        path: CSP_ACCOUNTS_PAGE,
      },
      {
        title: "GitOps Accounts",
        icon: CodeIcon,
        path: GITOPS_ACCOUNTS_PAGE,
      }
    ],
  },
  {
    title: "controle planes",
    items: [
      {
        title: "Controlplanes",
        icon: CubeTransparentIcon,
        path: CONTROLPLANS_PAGES,
      }
    ]
  },
  {
    items: [
      {
        title: "Settings",
        icon: CogIcon,
        path: SETTINGS_PAGE,
      }
    ],
  },
];
