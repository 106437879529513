import styles from "./CspAccountsListingDataTableContainer.module.css";
import { CspAccountsDatatable } from "components/cspAccounts/CspAccountsDatatable";
import { CspAccountsDto } from "types/csp/csp";

interface CspAccountsListingCardContainerProps {
  cspAccounts: CspAccountsDto[]
}

export const CspAccountsListingDataTableContainer = ({cspAccounts}: CspAccountsListingCardContainerProps) => {

  //handlers
  const handleClickService = (serviceId: string) => console.log(serviceId);

  return (
    <div className={styles.datatable_wrapper}>
      <CspAccountsDatatable
        cspAccounts={cspAccounts}
        onClickService={handleClickService}
      />
    </div>
  );
};
