import styles from "./GitopsAccountsListingDataTableContainer.module.css";
import { GitopsAccountsDatatable } from "components/gitopsAccounts/GitopsAccountsDatatable";
import { GitopsAccountsDto } from "types/gitops/gitops";

interface GitopsAccountsListingCardContainerProps {
  gitopsAccounts: GitopsAccountsDto[]
}

export const GitopsAccountsListingDataTableContainer = ({gitopsAccounts}: GitopsAccountsListingCardContainerProps) => {


  return (
    <div className={styles.datatable_wrapper}>
      <GitopsAccountsDatatable
        gitopsAccounts={gitopsAccounts}
      />
    </div>
  );
};
