import styles from "./ControlplanesPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { ControlplaneListingContainer } from "containers/controlplanes/ControlplanesListingContainer/ControlplanesListingContainer";

const ControlplanesPage = () => {

  return (
    <PageContent>
      <div className={styles.wrapper}>
          <div className={styles.header_wrapper}>
            <div>
              <div>
              <Heading>Controlplanes</Heading>
              <p>
                Manage your Controlplanes in one place (coming soon !!).
              </p>
              </div>
            </div>
          </div>
          <ControlplaneListingContainer />
        </div>
    </PageContent>
  );
};

export default ControlplanesPage;
