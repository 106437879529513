import { FoundationTemplatesDatatable } from "components/foundationTemplates/FoundationTemplatesDatatable/FoundationTemplatesDatatable";
import styles from "./FoundationTemplatesListingDataTableContainer.module.css";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";

interface FoundationTemplatesListingCardContainerProps {
  foundationTemplates: FoundationTemplatesDto[]
}

export const FoundationTemplatesListingDataTableContainer = ({foundationTemplates}: FoundationTemplatesListingCardContainerProps) => {

  //handlers
  const handleClickService = (serviceId: string) => console.log(serviceId);

  return (
    <div className={styles.datatable_wrapper}>
      <FoundationTemplatesDatatable
        foundationTemplates={foundationTemplates}
        onClickService={handleClickService}
      />
    </div>
  );
};
