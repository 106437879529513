
import styles from "./GitopsAccountsDatatable.module.css";
import { Table, TableBody, TableCell, TableHeader } from "components/sredx-ui/Table";
import { TableHead } from "components/sredx-ui/Table/TableHead/TableHead";
import { TableRow } from "components/sredx-ui/Table/TableRow/TableRow";
import { CodeIcon } from "components/Layout/Icon";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { GitopsAccountsDto } from "types/gitops/gitops";

const GITOPS_ACCOUNT_TABLE_HEADER = [
  "Name",
  "Owner",
  "Phase",
  "Message"
];

interface GitopsAccountsDatatableProps {
  isFetching?: boolean;
  gitopsAccounts?: GitopsAccountsDto[];
}

export const GitopsAccountsDatatable = ({
  gitopsAccounts = [],
  isFetching,
}: GitopsAccountsDatatableProps) => {

  return (
    <Table>
      <TableHeader>
        {GITOPS_ACCOUNT_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {gitopsAccounts?.map((gitopsAccount) => (
          <TableRow
            className={styles.row_wrapper}
            key={gitopsAccount.name}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                  <CodeIcon />
                </div>
                <span className={styles.service_name}>{gitopsAccount.name}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.owner}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.phase}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{gitopsAccount.message}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
