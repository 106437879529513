import { isControlplaneKubeConfigDownloadEnabled } from "./utils";
import { ClusterCardComponent } from "components/clusters";
import { useKubeConfig } from "services/kubeconfig/useKubeConfigHook";
import { Controlplane } from "types/controlplanes/controlplanes";

interface ControlplaneCardContainerProps {
  controlplane: Controlplane;
}

export const ControlplaneCardContainer = ({
  controlplane,
}: ControlplaneCardContainerProps) => {
  const { handleDownload, isClusterDownloading } = useKubeConfig();
  const isButtonLoading = isClusterDownloading(controlplane.name);
  const isDownloadEnabled = isControlplaneKubeConfigDownloadEnabled(controlplane);

  const handleOnDownload = (controlplaneName: string) => {
    handleDownload(controlplaneName);
  };
  return (
    <ClusterCardComponent
      cluster={controlplane}
      onDownload={handleOnDownload}
      isButtonLoading={isButtonLoading}
      isDownloadEnabled={isDownloadEnabled}
    />
  );
};
