import { CloudProvider } from "types/csp/providers";
import { CDTool } from "types/gitops/gitops";
import * as yup from "yup";

export interface Cluster {
  type: string;
  name: string;
  status: ClusterStatusType;
  message: string;
  age: string;
  cloudProvider: CloudProvider;
  accountName: string;
  username: string;
  repoName: string;
  repoUrl: string;
  gitOpsName: string;
  gitOpsTool?: CDTool;
  version: string;
  nodeType: string;
  nodeSize: number;
  cni: string;
  tools?: string[];
}

export interface ClusterStatusType {
  phase: StatusType;
  conditions: ConditionsType[];
}

export interface ConditionsType {
  message: string;
  type: string;
  status: string;
}

export const clusterCreationFoundations = [
  "sonar", "github-actions", "harbor", "vault", "cert-manager", 
  "kafka", "kubeapps", "litmus", "operators", "redmine", 
  "sealed-secrets", "keycloak"
];
export interface ManagedResource {
  id: string;
  type: ResourceType;
  age: string;
  link: string;
  ready: string;
  synced: string;
}

// export const clusterTypeEnum = ["multicloud"]
export const clusterTypeEnum = ["kapsule", "multicloud"]// cni ->kapsule:cilium + multicloud:kilo
export const k8sVersionEnum = ["1.28.2", "1.27.6", "1.26.9"]
export const nodesizeEnum = [1, 2, 3]
export const nodeTypeEnum = ["PRO2-S"]
export const nodeTypeEnumAws = ["small", "medium", "large"]
export const gitopsToolsEnum = ["argocd", "fluxcd"]
export const supportedProvidersEnum = ["aws", "scaleway"]
export const foundationTypeEnum = ["webserver", "certmanager","database", "Orchestration"]

export interface ClusterCreationDTO {
  name: string;
  type?: string | undefined;
  accountName: string;
  version: string;
  nodesize: number;
  nodeType?: string | undefined;
  repoName: string;
  gitOpsName: string;
  gitOpsTool: string;
  foundations: (string | undefined)[];
}


export const clusterCreationDSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().oneOf(clusterTypeEnum),
  accountName: yup.string().required(),
  version: yup.string().oneOf(k8sVersionEnum).required(),
  nodesize: yup.number().oneOf(nodesizeEnum).required(),
  nodeType: yup.string().oneOf(nodeTypeEnum.concat(nodeTypeEnumAws)),
  repoName: yup.string().required(),
  gitOpsName: yup.string().required(),
  gitOpsTool: yup.string().oneOf(gitopsToolsEnum).required(),
  foundations: yup.array().of(yup.string()).required(),
});

export type StatusType =
  | "RUNNING"
  | "READY"
  | "ERROR"
  | "PENDING"
  | "CREATING"
  | "DELETING"
  | "unknown";

export type ResourceType =
  | "VPC"
  | "Subnet"
  | "RouteTable"
  | "SecurityGroup"
  | "InternetGateway"
  | "EKS";