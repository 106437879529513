export const BASE_PATH = "/";
export const HOME_PAGE = "/home";
export const LANDING_PAGE = "/";


export const LOGIN_BASE_PATH = "/login";
export const UNAUTHORIZED_BASE_PATH = "/unauthorized";
export const NOT_FOUND_PATH = "/404";

export const CLUSTERS_PAGE = "/clusters";
export const CLUSTER_DETAILS_PAGE = "/clusters/:clusterId";

export const CSP_ACCOUNTS_PAGE = "/csp";

export const GITOPS_ACCOUNTS_PAGE = "/gitops";

export const DATABASES_PAGE = "/databases";

export const STACKS_PAGE = "/stacks";

export const FOUNDATION_TEMPLATES_PAGE = "/foundation-templates";

export const CONTROLPLANS_PAGES = "/controlplans";
export const CONTROLPLANS_DETAILS_PAGES = "/controlplans/:controlplaneName";

export const SETTINGS_PAGE = "/settings";