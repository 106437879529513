import ClusterDetailsInfo from "components/clusters/ClusterDetails/ClusterDetailsInfo";
import ClusterDetailsInfoSkeleton from "components/clusters/ClusterDetails/ClusterDetailsInfoSkeleton";
import { useGetControlplaneDetailInfo } from "services/controlplanes/useGetControlplaneDetailInfo";
import { useKubeConfig } from "services/kubeconfig/useKubeConfigHook";


const ControlplaneDetailsInfoFetchContainer = ({
  clusterId,
}: {
  clusterId: string;
}) => {
  const { data: cluster, isLoading, isError } = useGetControlplaneDetailInfo(clusterId);
  const { isClusterDownloading, handleDownload } = useKubeConfig();

  const handleControlplaneDownload = () => {
    if (cluster?.name) {
      handleDownload(cluster.name);
    }
  };

  if (isLoading) {
    return <ClusterDetailsInfoSkeleton  />;
  }

  if (isError || !cluster) {
    return <p>Error fetching controlplanes details.</p>;
  }

  return (
    <ClusterDetailsInfo
      isDownloading={isClusterDownloading(cluster.name)}
      onDownload={handleControlplaneDownload}
      cluster={cluster}
    />
  );
};

export default ControlplaneDetailsInfoFetchContainer;
