import { FoundationTemplateDeleteModal } from "components/foundationTemplates/FoundationTemplatesDeleteModal";
import styles from "./FoundationTemplatesListingCardContainer.module.css";
import { useModal } from "hooks/useModal";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { FoundationTemplateCard } from "components/foundationTemplates/FoundationTemplatesCard/FoundationTemplatesCard";


interface FoundationTemplatesListingCardContainerProps {
  foundationTemplates: FoundationTemplatesDto[]
}
export const FoundationTemplatesListingCardContainer = ({foundationTemplates}: FoundationTemplatesListingCardContainerProps) => {
  //hooks
  const { openModal, closeModal } = useModal();

  // handlers
  const handleDeleteFoundationTemplate = (foundationTemplate: FoundationTemplatesDto) => {
    openModal({
      content: (
        <FoundationTemplateDeleteModal
          foundationTemplate={foundationTemplate}
          onClose={closeModal}
        />
      ),
    });
  };
  
  return (
    <div className={styles.cardlist_wrapper}>
      {foundationTemplates?.map((foundationTemplate) => (
        <FoundationTemplateCard
          key={foundationTemplate.name}
          foundationTemplate={foundationTemplate as FoundationTemplatesDto}
          onDelete={handleDeleteFoundationTemplate}
        />
      ))}
    </div>
  );
};
