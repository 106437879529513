import styles from "./styles.module.css";
import { StatusType } from "types/clusters/clusters";
// import { sredxPallete } from "colors";

interface StatusTagProps {
  status: StatusType;
}

interface ColorResult {
    backgroundColor: string;
    color: string;
}
interface StatusColor {
    textColor: string;
    backgroundColor: string;
    status: string;
}

export const colorByStatus: StatusColor[] = [
    {
        textColor: '#28A745', // Green
        backgroundColor: '#D4EDDA', // Light Green
        status: "RUNNING"
    },
    {
        textColor: '#007BFF', // Blue
        backgroundColor: '#CCE5FF', // Light Blue
        status: "READY"
    },
    {
        textColor: '#6C757D', // Gray
        backgroundColor: '#E2E3E5', // Light Gray
        status: "DELETING"
    },
    {
        textColor: '#DC3545', // Red
        backgroundColor: '#F8D7DA', // Light Red
        status: "ERROR"
    },
    {
        textColor: '#FFC107', // Yellow
        backgroundColor: '#FFF3CD', // Light Yellow
        status: "PENDING"
    }
];

interface ColorResult {
    backgroundColor: string;
    color: string;
}

function getColorByStatus(status: string): ColorResult {
    const statusColor: StatusColor | undefined = colorByStatus.find((item: StatusColor) => item.status === status);
    if (statusColor) {
        return {
            backgroundColor: statusColor.backgroundColor,
            color: statusColor.textColor
        };
    } else {
        return {
            backgroundColor: '#E2E3E5',
            color: '#6C757D'
        };
    }
}

export const StatusTag = ({ status}: StatusTagProps) => {
    const color = getColorByStatus(status)
  return (
    <div
      className={styles.tag}
      style={{...color, border:"none"}}
    >
        <span
          style={{
            backgroundColor: color.color,
            height: 8,
            width: 8,
            borderRadius: "50%",
          }}
        />
      <span>{status}</span>
    </div>
  );
};
