
import { isArray } from "lodash";
import React, { useMemo, useState } from "react";
import { generatePath, Link } from "react-router-dom";
import styles from "./ClusterListingContainer.module.css";
import { Loader } from "components/sredx-ui";
import { Text } from "components/sredx-ui/Text";
import { Card } from "components/sredx-ui/Card";
import { Empty } from "components/sredx-ui/Empty";
import { Searchbar } from "components/sredx-ui/Searchbar";
import { useModal } from "hooks/useModal";
import { useGetClusters } from "services/clusters/useGetClusters";
import { ClusterCreateContainer } from "../ClusterCreateContainer/ClusterCreateContainer";
import { ClusterCardContainer } from "../ClusterCardContainer";
import { EdgeClusterIcon } from "components/Layout/Icon";

export const ClusterListingContainer = () => {
  // hooks
  const { openModal, closeModal } = useModal();

  //query
  const { data: clusters, isLoading } = useGetClusters();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const displayedClusters = useMemo(() => {
    if (!clusters || !isArray(clusters)) {
      return [];
    }
    return clusters.filter(
      (cluster) =>
        cluster.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
    );
  }, [clusters, searchQuery]);

  //handlers
  const handleOnSearch = (value: string) => {
    setSearchQuery(value);
  };
  const getPath = (clusterName: string) => {
    return generatePath(":name", { name: clusterName });
  };

  const handleOnCreateCluster = () => {
    openModal({
      content: (
        <ClusterCreateContainer onCancel={closeModal} />
      ),
      options: {
        width: "1000px",
      },
    });
  };

  //ui waterfall
  if (isLoading) {
    return (
      <div className={styles.loader_wrapper}>
        <Loader />
        <Text>Loading clusters...</Text>
      </div>
    );
  }
  if (!displayedClusters || displayedClusters.length === 0) {
    return (
      <>
        <div className={styles.search_filter_wrapper}>
          <div className={styles.searchbar_wrapper}>
            <Searchbar
              value={searchQuery}
              onChange={handleOnSearch}
              placeholder="Search clusters by name"
            />
          </div>
        </div>
        <Card>
          <Empty
            message={"No clusters were to be found ..."}
            onActionClick={handleOnCreateCluster}
            actionLabel={"Create new cluster"}
          />
        </Card>
      </>
    );
  }
  return (
    <>
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            value={searchQuery}
            onChange={handleOnSearch}
            placeholder="Search clusters by name"
          />
        </div>
      </div>
      <div className={styles.content_wrapper}>
        <button
          className={styles.new_cluster_btn}
          onClick={handleOnCreateCluster}
        >
          <div className={styles.new_cluster_icon}>
            <EdgeClusterIcon />
          </div>
          <div>Create new cluster</div>
        </button>
        {displayedClusters.map((cluster) => (
          <Link key={cluster.name} to={getPath(cluster.name)}>
            <ClusterCardContainer cluster={cluster} />
          </Link>
        ))}
      </div>
    </>
  );
};
