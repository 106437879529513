import { FormField } from "components/basic/FormField";
import { SelectInput } from "components/basic/SelectInput";
import { resolveToolUrl } from "components/clusters/utils";
import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { useGetGitopsAccounts } from "services/gitops/useGetGitopsAccounts";
import { foundationTypeEnum } from "types/clusters/clusters";

interface FoundationTemplateCreateFormProps {
  errors?: any;
}
export const FoundationTemplateCreateForm = ({
  errors,
}: FoundationTemplateCreateFormProps) => {
  const { data: gitopsAccount = [] } = useGetGitopsAccounts();
  return (
    <div className={`flex flex-col gap-4`}>
      <FormField
        id="name"
        name="name"
        label="Foundation Template name"
        placeholder="Enter a foundation template name"
        component={TextInput}
        error={errors?.name?.message}
      />
      <div className="flex justify-between gap-2">
        <FormField
          id="chart"
          name="chart"
          label="Chart name"
          className="w-full"
          placeholder="Enter enter the chart name"
          component={TextInput}
          error={errors?.chart?.message}
        />
        <FormField
          id="version"
          name="version"
          label="Version"
          className="w-full"
          placeholder="Enter the chart version"
          component={TextInput}
          error={errors?.version?.message}
        />
      </div>
      <FormField
        id="repoUrl"
        name="repoUrl"
        label="Repository Url"
        placeholder="Enter the repository url"
        className="w-full"
        component={TextInput}
        error={errors?.accessKey?.message}
      />
      <FormField
        id="type"
        name="type"
        label="Type"
        placeholder="Select the type"
        className="w-full"
        component={SelectInput}
        options={foundationTypeEnum.map((foundation) => {
          const ToolImg = resolveToolUrl(foundation);
          return ToolImg ? {
              value: foundation,
              label: (
                <>
                  {foundation}
                </>
              ),
            } : null;
        }).filter(Boolean)}
        errors={errors?.type?.message}
      />
      <FormField
        id="gitopsAccount"
        name="gitopsAccount"
        label="Gitops Account"
        placeholder="Select gitops account"
        className="w-full"
        component={SelectInput}
        options={gitopsAccount.map((gitopsAccount) => {
          const ToolImg = resolveToolUrl(gitopsAccount.name);
          return ToolImg ? {
              value: gitopsAccount.name,
              label: (
                <>
                  <ToolImg className="h-4" />
                  {gitopsAccount.name}
                </>
              ),
            } : null;
        }).filter(Boolean)}
        errors={errors?.gitopsAccount?.message}
      />
    </div>
  );
};
