import { TrashIcon } from "@heroicons/react/outline";
import styles from "./styles.module.css";
import { FoundationTemplatesDto } from "types/foundationtemplates/foundationtemplates";
import { ListingCard } from "components/sredx-ui/ListingCard";
import { StatusTag } from "components/shared/StatusTag/StatusTag";

interface FoundationTemplateCardProps {
  foundationTemplate: FoundationTemplatesDto;
  onDelete?: (foundationTemplate: FoundationTemplatesDto) => void;
}

export const FoundationTemplateCard = ({
  foundationTemplate,
  onDelete,
}: FoundationTemplateCardProps) => {
  return (
    <ListingCard>
      <div>
        <div className={styles.head}>
          <h2 className={styles.title}>{foundationTemplate.name}</h2>
          <div className="flex gap-2 flex-shrink-0">
            {onDelete && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(foundationTemplate);
                }}
              >
                <TrashIcon className="h-4 text-slate-500" />
              </button>
            )}
          </div>
        </div>
        <div className="flex gap-2 flex-wrap">
          <StatusTag status={foundationTemplate.phase} />
        </div>
      </div>
      {/* Body Section */}
      <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Name</h3>
          <div className="flex gap-2" data-testid="tools">
            {/* annotations */}
            <div className={"flex gap-2"}>

              <span className="text-slate-500 text-xs">{foundationTemplate.name}</span>

            </div>
          </div>
        </div>
        <div className={styles.keyValue}>
            <h3 className={styles.subtitle}>Type</h3>
            <div className="flex gap-2" data-testid="tools">
                {/* annotations */}
                <div className={"flex gap-2"}>

                    <span className="text-slate-500 text-xs">{foundationTemplate.type}</span>

                </div>
            </div>
        </div>
      <div className="flex flex-col gap-2">
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Chart</h3>
          <div className="flex items-center gap-2">
            <span className="text-slate-500 text-xs">{foundationTemplate.chart}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Repository URL</h3>
          <div className="flex items-center gap-2">
            <span className="text-slate-500 text-xs">{foundationTemplate.repoUrl}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className={styles.keyValue}>
          <h3 className={styles.subtitle}>Version</h3>
          <div className="flex items-center gap-2">
            <span className="text-slate-500 text-xs">{foundationTemplate.version}</span>
          </div>
        </div>
      </div>
      {
        foundationTemplate.phase != "READY" && foundationTemplate.conditions?.filter(condition => condition.status != false).length > 0 &&
        <div className="flex flex-col gap-2">
          <div className={styles.keyValue}>
            <h3 className={styles.subtitle}>Error Message</h3>
            <div className="flex items-center gap-2">
              <span className="text-red-500 text-xs">{foundationTemplate.conditions?.length > 0 && foundationTemplate.conditions[0].message}</span>
            </div>
          </div>
        </div>
      }
      
      
      
    </ListingCard>
  );
};