
import styles from "./CspAccountsDatatable.module.css";
import { Table, TableBody, TableCell, TableHeader } from "components/sredx-ui/Table";
import { TableHead } from "components/sredx-ui/Table/TableHead/TableHead";
import { TableRow } from "components/sredx-ui/Table/TableRow/TableRow";
import { CloudIcon } from "components/Layout/Icon";
import { ProgressBar } from "components/sredx-ui/ProgressBar";
import { CspAccountsDto } from "types/csp/csp";

const CSP_ACCOUNT_TABLE_HEADER = [
  "Name",
  "Provider",
  "Phase",
  "Message"
];

interface CspAccountsDatatableProps {
  isFetching?: boolean;
  cspAccounts?: CspAccountsDto[];
  onClickService: (serviceId: string) => void;
}

export const CspAccountsDatatable = ({
  cspAccounts = [],
  onClickService,
  isFetching,
}: CspAccountsDatatableProps) => {

  return (
    <Table>
      <TableHeader>
        {CSP_ACCOUNT_TABLE_HEADER.map((header) => (
          <TableHead key={header}>{header}</TableHead>
        ))}
      </TableHeader>
      <TableBody>
        {isFetching && <ProgressBar position={"absolute"} />}
        {cspAccounts?.map((cspAccount) => (
          <TableRow
            className={styles.row_wrapper}
            key={cspAccount.name}
            onClick={() => onClickService(cspAccount.name)}
          >
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <div className={styles.service_icon}>
                  <CloudIcon />
                </div>
                <span className={styles.service_name}>{cspAccount.name}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.provider}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.phase}</span>
              </div>
            </TableCell>
            <TableCell className={styles.first_cell}>
              <div className={styles.service_name_wrapper}>
                <span className={styles.service_name}>{cspAccount.message}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
