export const API_ENDPOINTS = {
  // clusters
  getClusters: () => `api/v1/clusters`,
  createCluster: () => `api/v1/clusters`,
  deleteCluster: (name: string) => `api/v1/clusters/${name}`,
  getClusterDetails: (name: string | undefined) => `api/v1/clusters/${name}`,
  getClusterManagedResources: (name: string) => `api/v1/clusters/${name}/managed-resources`,
  getClusterFoundations: (name: string) => `api/v1/clusters/${name}/foundations`,

  // controlplanes
  getControlplanes: () => `api/v1/controlplanes`,
  createControlplane: () => `api/v1/controlplanes`,
  deleteControlplane: (name: string) => `api/v1/controlplanes/${name}`,
  getControlplaneDetails: (name: string) => `api/v1/controlplanes/${name}`,
  getControlplaneManagedResources: (name: string) => `api/v1/controlplanes/${name}/managed-resources`,

  // csp accounts
  getCspAccounts: () => `api/v1/accounts`,
  createCspAccount: () => `api/v1/accounts`,
  deleteCspAccount: (name: string) => `api/v1/accounts/${name}`,

  // foundation templates
  getFoundationTemplates: () => `api/v1/foundationtemplates`,
  createFoundationTemplate: () => `api/v1/foundationtemplates`,
  deleteFoundationTemplate: (name: string) => `api/v1/foundationtemplates/${name}`,

  // gitops account
  getGitopsAccounts: () => `api/v1/gitops`,
  createGitopsAccount: () => `api/v1/gitops`,
  deleteGitopsAccount: (name: string) => `api/v1/gitops/${name}`,

  // kubeconfig
  getKubeConfig: (id: string) =>
    `api/v1/clusters/${id}/kubeconfig`,

};
