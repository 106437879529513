import styles from "./HomePage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";

const HomePage = () => {
  return (
    <PageContent>
      <div className={styles.header_wrapper}>
        <div>
          <Heading>Home</Heading>
          <p>This is your team home, The gateway to everything</p>
        </div>
      </div>
    </PageContent>
  );
};

export default HomePage;
