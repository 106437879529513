import { FormField } from "components/basic/FormField";
import { SelectInput } from "components/basic/SelectInput";
import { TextInput } from "components/sredx-ui/Forms/TextInput";
import { clusterTypeEnum, gitopsToolsEnum, k8sVersionEnum, nodeTypeEnum, nodeTypeEnumAws, nodesizeEnum } from "types/clusters/clusters";
import { resolveToolUrl } from "../utils";

interface ClusterCreateFormProps {
  errors?: any;
  cloudAccounts: { name: string; provider: string }[];
  gitopsAccounts: { name: string; owner: string }[];
  display: boolean;
  selectedAccount: string;
}
export const ClusterCreateForm = ({
  errors,
  cloudAccounts,
  gitopsAccounts,
  display,
  selectedAccount,
}: ClusterCreateFormProps) => {
  console.log(errors);
  let provider = cloudAccounts.find(account => account.name === selectedAccount)?.provider;
  return (
    <div className={`flex flex-col gap-4  ${display ? "" : "hidden"}`}>
      <FormField
        id="name"
        name="name"
        label="Cluster name"
        placeholder="Enter Cluster name"
        component={TextInput}
        error={errors?.name?.message}
      />
      <FormField
        id="version"
        name="version"
        label="Kubernetes cluster version"
        placeholder="Select kubernetes cluster version"
        component={SelectInput}
        options={k8sVersionEnum.map((k8sVersion) => {
          const ToolImg = resolveToolUrl(k8sVersion);
            return ToolImg ? {
              value: k8sVersion,
              label: (
                <>
                  <ToolImg className="h-4" />
                  {k8sVersion}
                </>
              ),
            }: null;
        })}
        errors={errors?.version?.message}
      />
      <FormField
        id="accountName"
        name="accountName"
        label="Cloud Account"
        placeholder="Select cloud account"
        className="w-full"
        component={SelectInput}
        options={cloudAccounts?.map((accountName) => {
          const ToolImg = resolveToolUrl(accountName.provider);
          return ToolImg ? {
              value: accountName.name,
              label: (
                <>
                  <ToolImg className="h-4" />
                  {accountName.name}
                </>
              ),
            } : null;
        }).filter(Boolean)}
        errors={errors?.accountName?.message}
      />
      {provider === "scaleway" && (
        <FormField
          id="type"
          name="type"
          label="Cluster Type"
          placeholder="Select cluster type"
          className="w-full"
          component={SelectInput}
          options={clusterTypeEnum.map((clusterType) => {
            const ToolImg = resolveToolUrl(clusterType);
              return ToolImg ? {
                value: clusterType,
                label: (
                  <>
                    <ToolImg className="h-4" />
                    {clusterType}
                  </>
                ),
              } : null;
          }).filter(Boolean)}
          errors={errors?.type?.message}
        />
        )}
      <div className="flex justify-between gap-2">

      <FormField
        id="nodeType"
        name="nodeType"
        label="Node Type"
        placeholder="Select node type"
        className="w-full"
        component={SelectInput}
        options={nodeTypeOptions(provider)}
        errors={errors?.nodeType?.message}
      />
      <FormField
        id="nodesize"
        name="nodesize"
        label="Node Size"
        placeholder="Select node size"
        className="w-full"
        component={SelectInput}
        options={nodesizeEnum.map((nodeSize) => {
            return {
              value: nodeSize,
              label: (
                <>
                  {nodeSize}
                </>
              ),
            };
        })}
        errors={errors?.nodesize?.message}
      />
      </div>
      <div className="flex justify-between gap-2">
      <FormField
        id="gitOpsName"
        name="gitOpsName"
        label="GitOps"
        placeholder="Select GitOps account"
        className="w-full"
        component={SelectInput}
        options={gitopsAccounts?.map((gitOpsName) => {
            return {
              value: gitOpsName.name,
              label: (
                <>
                  {gitOpsName.name}
                </>
              ),
            };
        })}
        errors={errors?.gitOpsName?.message}
      />
       <FormField
        id="repoName"
        name="repoName"
        label="Gitops Repository Name"
        placeholder="Enter a Repository Name"
        className="w-full"
        component={TextInput}
        error={errors?.repoName?.message}
      />
      </div>
      <div className="flex justify-between gap-2">
      <FormField
        id="gitOpsTool"
        name="gitOpsTool"
        label="GitOps Tool"
        placeholder="Select GitOps Tool"
        className="w-full"
        component={SelectInput}
        options={gitopsToolsEnum.map((gitOpsTool) => {
          const ToolImg = resolveToolUrl(gitOpsTool);
            return ToolImg ?{
              value: gitOpsTool,
              label: (
                <>
                  <ToolImg className="h-4" />
                  {gitOpsTool}
                </>
              ),
            } : null;
        })}
        errors={errors?.gitOpsTool?.message}
      />
      </div>
    </div>
  );
};


const nodeTypeOptions = (provider: string| undefined) => {
  if (provider === "scaleway") {
    return nodeTypeEnum.map((nodeType) => {
      const ToolImg = resolveToolUrl(nodeType);
      return ToolImg ? {
        value: nodeType,
        label: (
          <>
            <ToolImg className="h-4" />
            {nodeType}
          </>
        ),
      } : null;
    });
  }
  if (provider === "aws") {
    return nodeTypeEnumAws.map((nodeType) => {
      return {
        value: nodeType,
        label: (
          <>
            {nodeType}
          </>
        ),
      };
    });
  }
  return [];
};