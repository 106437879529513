
import { debounce, isArray } from "lodash";
import { useState } from "react";
import styles from "./FoundationTemplatesListingContainer.module.css";
import { Searchbar } from "components/sredx-ui/Searchbar";
import { ViewToggler } from "components/sredx-ui/ViewToggler";
import { FoundationTemplatesListingDataTableContainer } from "./FoundationTemplatesListingDataTableContainer";
import { FoundationTemplatesListingCardContainer } from "./FoundationTemplatesListingCardContainer";
import { useGetFoundationTemplates } from "services/foundationtemplates/useGetFoundationTemplates";
import { Loader } from "components/sredx-ui";
import { Text } from "components/sredx-ui/Text";
import { Card } from "components/sredx-ui/Card";
import { Empty } from "components/sredx-ui/Empty";

interface FoundationTemplatesListingContainerProps {
  onAddFoundationTemplateClick:  () => void
}
export const FoundationTemplatesListingContainer = ({onAddFoundationTemplateClick}: FoundationTemplatesListingContainerProps) => {
  const {
    data: foundationTemplates,
    isFetching,
    isLoading, 
  } = useGetFoundationTemplates();

  //hooks
  const [view, setView] = useState<"card" | "datatable">("card");

  // Events handler
  const toggleView = () => {
    setView(view === "card" ? "datatable" : "card");
  };

  const handleSearch = (e: string) => {
    console.log(e)
  };
  const debouncedHandleSearch = debounce(handleSearch, 400);

  if (isLoading || isFetching)
    return (
      <div className={styles.loader_wrapper}>
        <Loader size={"large"}  />
        <Text>Loading Foundation Templates...</Text>
      </div>
    );
    if (!foundationTemplates || !isArray(foundationTemplates)) {
      return (
        <Card className="mt-9">
          <Empty
            message={"No foundation templates found for this control plane."}
            actionLabel={"Add new foundation template"}
            onActionClick={onAddFoundationTemplateClick}
          />
        </Card>
      );
    }
    const resolveView = () => {
      if (view === "card") {
        return <FoundationTemplatesListingCardContainer  foundationTemplates={foundationTemplates} />;
      }
      return <FoundationTemplatesListingDataTableContainer foundationTemplates={foundationTemplates} />;
    };

  return (
    <div className={styles.wrapper}>
      {/*  Search & Filter  */}
      <div className={styles.search_filter_wrapper}>
        <div className={styles.searchbar_wrapper}>
          <Searchbar
            onChange={debouncedHandleSearch}
            placeholder="Search Foundation Templates by name"
          />
        </div>
        <div className={styles.view_toggler}>
          <ViewToggler value={view} onToggle={toggleView} />
        </div>
      </div>
      {resolveView()}
    </div>
  );
  
};
