import { ClusterStatusType, clusterTypeEnum, k8sVersionEnum, nodeTypeEnum, nodesizeEnum } from "types/clusters/clusters";
import { CloudProvider } from "types/csp/providers";
import * as yup from "yup";

export interface Controlplane {
  name: string;
  type: string;
  message: string;
  age: string;
  cloudProvider: CloudProvider;
  accountName: string;
  username: string;
  repoName: string;
  repoUrl: string;
  gitOpsName: string;
  version: string;
  nodeType: string;
  nodeSize: number;
  cni: string;
  status: ClusterStatusType;
}

export interface ControlplaneCreationDTO {
  name: string;
  type: string;
  accountName: string;
  version: string;
  nodesize: number;
  nodeType: string;
  repoName: string;
  gitOpsName: string;
}

export const controlplanesCreationDSchema = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().oneOf(clusterTypeEnum).required(),
  accountName: yup.string().required(),
  version: yup.string().oneOf(k8sVersionEnum).required(),
  nodesize: yup.number().oneOf(nodesizeEnum).required(),
  nodeType: yup.string().oneOf(nodeTypeEnum).required(),
  repoName: yup.string().required(),
  gitOpsName: yup.string().required(),
});