import { useMutation, useQueryClient } from "react-query";

import { MUTATION_KEYS, QUERY_KEYS } from "config/queryKeys";
import { API_ENDPOINTS } from "config/apiPaths";
import { ClientApi } from "services/clientApi";
import { ControlplaneCreationDTO } from "types/controlplanes/controlplanes";

export const useCreateControlplane = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createControlplane],
    mutationFn: (data: ControlplaneCreationDTO) => {
      return ClientApi.post(API_ENDPOINTS.createControlplane(), data);
    },
    onSuccess: async () => {
      
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.controlplanes], {
          exact: false,
        });
      }, 5000); 
    },
  });
};
  