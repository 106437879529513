import { useMutation, useQueryClient } from "react-query";

import { MUTATION_KEYS, QUERY_KEYS } from "config/queryKeys";
import { API_ENDPOINTS } from "config/apiPaths";
import { ClientApi } from "services/clientApi";
import { ClusterCreationDTO } from "types/clusters/clusters";

export const useCreateCluster = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATION_KEYS.createCluster],
    mutationFn: (data: ClusterCreationDTO) => {
      return ClientApi.post(API_ENDPOINTS.createCluster(), data);
    },
    onSuccess: async () => {
      
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.clusters], {
          exact: false,
        });
      }, 5000); 
    },
  });
};
  