import { Button, ButtonLabel } from "components/sredx-ui/Button";
import styles from "./FoundationTemplatesPage.module.css";
import { PageContent } from "components/sredx-ui/PageContent";
import { Heading } from "components/sredx-ui/Text";
import { useModal } from "hooks/useModal";
import { FoundationTemplateCreateContainer } from "containers/foundationtemplates/FoundationTemplatesCreateContainer/FoundationTemplatesCreateContainer";
import { FoundationTemplatesListingContainer } from "containers/foundationtemplates/FoundationTemplatesListingContainer/FoundationTemplatesListingContainer";

const FoundationTemplatesPage = () => {
  const { openModal, closeModal } = useModal();

  const onAddFoundationTemplateClick = () =>{
    openModal({
      content: <FoundationTemplateCreateContainer onCancel={closeModal} />
    });
  }

  return (
    <PageContent>
    <div className={styles.header_wrapper}>
      <div>
        <div>
        <Heading>Foundation Templates</Heading>
        <p>
        All your Foundations templates in one plane.
        </p>
        </div>
      </div>
      <div>
        <Button onClick={onAddFoundationTemplateClick}>
          <ButtonLabel >Add Foundation Templates</ButtonLabel>
        </Button>
      </div>
    </div>
    <FoundationTemplatesListingContainer onAddFoundationTemplateClick={onAddFoundationTemplateClick} />
</PageContent>
  );
};

export default FoundationTemplatesPage;
